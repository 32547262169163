import PropTypes from 'prop-types';
import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import Img from 'gatsby-image';
import Truncate from 'react-truncate-html';
import Anchor from '../../ui/Anchor';
import Button from '../../ui/button';
import AuthorMeta from '../author-meta';
import NewsMeta from '../news-meta';
import {
  NewsFooter,
  NewsHeader,
  NewsHeaderMeta,
  NewsInfo,
  NewsTitle,
  NewsWrapper,
  NewsFooterLeft,
  NewsThumb,
  NewsExcerpt,
} from './news.style';
import { slugify } from '../../../utils/utilFunctions';
import Categories from '../categories';

const News = ({ content, metaStyle, categoryBoxStyle, rootPath, ...restProps }) => {
  const {
    title,
    slug,
    author,
    createdAt,
    publishDate,
    category,
    image,
    contentSession1: {
      childContentfulRichText: { html },
    },
  } = content;
  const postDate = publishDate || createdAt;
  return (
    <>
      <NewsWrapper {...restProps}>
        <NewsThumb>
          <Anchor path={`${rootPath}/${slug}`}>
            <Img fluid={image.fluid} alt={title} />
          </Anchor>
        </NewsThumb>
        <NewsInfo>
          <NewsHeader>
            <Categories {...categoryBoxStyle} categories={category} />
            {title && (
              <NewsTitle>
                <Anchor path={`${rootPath}/${slug}`} key={slug} hover={{ color: 'secondary' }}>
                  {title}
                </Anchor>
              </NewsTitle>
            )}
            <NewsHeaderMeta>
              {author && <AuthorMeta {...metaStyle} slug={author.slug} name={author.name} />}
              {postDate && <NewsMeta {...metaStyle} text={postDate} icon={<FaCalendarAlt />} />}
            </NewsHeaderMeta>
          </NewsHeader>
          <NewsExcerpt>
            <Truncate
              lines={5}
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </NewsExcerpt>
          <NewsFooter>
            <NewsFooterLeft>
              <Button to={`${rootPath}/${slug}`} hover="2">
                Ler mais
              </Button>
            </NewsFooterLeft>
            {/* <NewsFooterRight>
              <SocialShare title={title} url={`/${slug}`} />
            </NewsFooterRight> */}
          </NewsFooter>
        </NewsInfo>
      </NewsWrapper>
    </>
  );
};

News.propTypes = {
  rootPath: PropTypes.string,
  content: PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string }).isRequired,
  metaStyle: PropTypes.shape({
    mt: PropTypes.string,
    mr: PropTypes.string,
  }),
  categoryBoxStyle: PropTypes.shape({
    mb: PropTypes.string,
  }),
};

News.defaultProps = {
  rootPath: '/blog',
  metaStyle: {
    mt: '10px',
    mr: '20px',
  },
  categoryBoxStyle: {
    mb: '18px',
  },
};

export default News;
