import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import News from '../../components/newsletter/layout-two';
import Pagination from '../../components/newsletter/pagination';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import { Col, Container, Row } from '../../components/ui/Wrapper';
import Sidebar from '../../containers/newsletter/sidebar';
import Footer from '../../containers/Layout/Footer';
import Header from '../../containers/Layout/Header';
import { NewsBox, NewsBoxWrapper, NewsListWrapper } from './news-list.style';

const NewsList = ({ data, pageContext, location }) => {
  const blogs = data.allContentfulNews.edges;

  const { humanPageNumber, numberOfPages, authorSlug, categorySlug } = pageContext;

  const getFilterSlug = () => {
    if (authorSlug) return `/autor/${authorSlug}`;
    if (categorySlug) return `/categoria/${categorySlug}`;
    return '';
  };

  const rootPath = `/noticias`;
  return (
    <>
      <SEO title={`News: Página ${humanPageNumber}`} />
      <Header menuBasedOnCookies />
      <PageHeader pageContext={pageContext} location={location} title="Notícias" />
      <main className="site-wrapper-reveal">
        <NewsListWrapper>
          <Container>
            <Row>
              <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
                <Sidebar />
              </Col>
              <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
                <NewsBoxWrapper>
                  {blogs.map(({ node: blog }) => (
                    <NewsBox key={blog.slug}>
                      <News content={blog} rootPath={rootPath} />
                    </NewsBox>
                  ))}
                </NewsBoxWrapper>
                <Pagination rootPage={rootPath} currentPage={humanPageNumber} numberOfPages={numberOfPages} />
              </Col>
            </Row>
          </Container>
        </NewsListWrapper>
      </main>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!, $categorySlugRegex: String = "//", $authorSlugRegex: String = "//") {
    allContentfulNews(
      sort: { fields: publishDate, order: DESC }
      skip: $skip
      limit: $limit
      filter: {
        author: { slug: { regex: $authorSlugRegex } }
        category: { elemMatch: { slug: { regex: $categorySlugRegex } } }
      }
    ) {
      edges {
        node {
          slug
          title
          createdAt(formatString: "LL", locale: "pt-BR")
          publishDate(formatString: "LL", locale: "pt-BR")
          contentSession1 {
            childContentfulRichText {
              html
            }
          }
          author {
            name
            slug
          }
          category {
            slug
            title
          }
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

NewsList.propTypes = {
  pageContext: PropTypes.shape({
    humanPageNumber: PropTypes.number,
    numberOfPages: PropTypes.number,
    authorSlug: PropTypes.string,
    categorySlug: PropTypes.string,
  }).isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default NewsList;
