import React from 'react';
import { MdTrendingFlat } from 'react-icons/md';
import { useStaticQuery, graphql } from 'gatsby';
import { List, ListItem, ListLink } from './recent-post-list.style';

const RecentPostList = ({ listStyle }) => {
  const blogData = useStaticQuery(graphql`
    query RecentNewsQueryData {
      allContentfulNews(sort: { fields: publishDate, order: DESC }, limit: 5) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `);

  const latestNewss = blogData.allContentfulNews.edges || [];

  return (
    <List>
      {latestNewss &&
        latestNewss.map((latestNews, i) => (
          <ListItem key={`${latestNews.node.slug}-${i}`}>
            <ListLink path={`/noticias/${latestNews.node.slug}`}>
              <MdTrendingFlat className="icon icon-1" />
              <MdTrendingFlat className="icon icon-2" />
              <span>{latestNews.node.title}</span>
            </ListLink>
          </ListItem>
        ))}
    </List>
  );
};

export default RecentPostList;
